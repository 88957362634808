/* src/App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.page {
  padding: 20px;
}

h1, h2 {
  color: #333;
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"],
input[type="email"],
input[type="password"],
button {
  padding: 8px;
  margin-top: 5px;
  width: 100%;
  max-width: 300px;
}

button {
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}
